var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useEffect, useState, useContext } from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import { Button, ButtonGroup, Dialog } from "@wayfarer/components";
import { ExitToApp } from "@material-ui/icons";
import { OktaConfigurationError } from "./ConfigurationError";
import axios from "axios";
var Tokens = createContext(null);
var search = window.location.search;
var params = new URLSearchParams(search);
var regionParam = params.get('region');
var oktaIssuer = "";
var oktaClientId = "";
var oktaScope = [];
var excludeAuth = [
    "#/signout",
    "#/loginInternal",
    "#/loginNA",
    "#/loginUK"
];
if (regionParam) {
    localStorage.setItem('region', regionParam);
}
else {
    regionParam = localStorage.getItem('region');
}
switch (regionParam) {
    case 'NAB':
        oktaIssuer = process.env.OKTA_ISSUER_NAB || "";
        oktaClientId = process.env.OKTA_CLIENT_ID_NAB || "";
        oktaScope = ["openid", "profile", "email", "productOptions", "offline_access"];
        break;
    case 'EUB':
        oktaIssuer = process.env.OKTA_ISSUER_EUB || "";
        oktaClientId = process.env.OKTA_CLIENT_ID_EUB || "";
        oktaScope = ["openid", "profile", "email", "productOptions", "offline_access"];
        break;
    case 'Internal':
        oktaIssuer = process.env.OKTA_ISSUER || "";
        oktaClientId = process.env.OKTA_CLIENT_ID || "";
        oktaScope = ["openid", "profile", "email", "offline_access"];
        break;
    default:
        oktaIssuer = process.env.OKTA_ISSUER || "";
        oktaClientId = process.env.OKTA_CLIENT_ID || "";
        oktaScope = ["openid", "profile", "email", "offline_access"];
        if (excludeAuth.indexOf(window.location.hash) === -1) {
            window.location.assign("/#/select-region");
        }
}
var auth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    services: {
        autoRenew: true,
    },
    // tokenManager: {
    //   expireEarlySeconds: 3570     // normal exp = 3600, so 3570 means "expire after 30 seconds"
    // },
    scopes: oktaScope,
    devMode: process.env.NODE_ENV !== "production",
    postLogoutRedirectUri: window.location.origin + "/#/signout",
});
export default function Auth(_a) {
    var _this = this;
    var children = _a.children;
    var _b = useState({ tag: "pending" }), status = _b[0], setStatus = _b[1];
    var onFocus = function () {
        window.location.reload();
    };
    useEffect(function () {
        if (status.tag === "error" && status.dismissed === "no") {
            //Add a listener on the window object
            window.addEventListener("focus", onFocus);
            //Clean listeners
            return function () {
                window.removeEventListener("focus", onFocus);
            };
        }
    }, [status]);
    useEffect(function () {
        var canceler = false;
        var tagStatus = "pending";
        auth.start();
        auth.tokenManager.on("error", function (err) {
            tagStatus = "error";
            setStatus({ tag: "error", dismissed: "yes" });
            // console.error("errrrr", err);
        });
        auth.tokenManager.on("expired", function (exp) {
            tagStatus = "expired";
            // console.error("exppp", exp);
            setStatus({ tag: "error", dismissed: "no" });
        });
        // auth.tokenManager.on("renewed", function (x) {
        //   console.log(x, 'renewed');
        // });
        var _setRegionFlag = function (_oktaIssuer) {
            switch (_oktaIssuer) {
                case process.env.OKTA_ISSUER_NAB:
                    return 'NAB';
                case process.env.OKTA_ISSUER_EUB:
                    return 'EUB';
                default:
                    return 'Internal';
            }
        };
        var getAppConfig = function (tokens) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        res = {};
                        axios.defaults.headers.common = {
                            'Authorization': "Bearer ".concat(tokens.accessToken),
                            'Region': _setRegionFlag((_a = tokens === null || tokens === void 0 ? void 0 : tokens.claims) === null || _a === void 0 ? void 0 : _a.iss)
                        };
                        return [4 /*yield*/, axios.get(process.env.MYEXPERIAN_API_BASE_URL + '/rba')
                                .then(function (response) {
                                res = response.data;
                            })
                                .catch(function (error) {
                                var _a;
                                var err_code = ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) || error.code;
                                res = { "Error": err_code };
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, res];
                }
            });
        }); };
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var tokens, appConfig, accessToken, idToken, refreshToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!auth.isLoginRedirect()) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.token.parseFromUrl()];
                    case 1:
                        (tokens = (_a.sent()).tokens);
                        if (canceler) {
                            return [2 /*return*/];
                        }
                        auth.tokenManager.setTokens(tokens);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, auth.tokenManager.getTokens()];
                    case 3:
                        tokens = _a.sent();
                        if (canceler) {
                            return [2 /*return*/];
                        }
                        _a.label = 4;
                    case 4: return [4 /*yield*/, getAppConfig(tokens === null || tokens === void 0 ? void 0 : tokens.accessToken)];
                    case 5:
                        appConfig = _a.sent();
                        accessToken = tokens.accessToken, idToken = tokens.idToken, refreshToken = tokens.refreshToken;
                        if (!accessToken) {
                            throw new Error("No access token. This is a problem because an access token is how the API authorizes the user.");
                        }
                        if (auth.tokenManager.hasExpired(accessToken)) {
                            throw new Error("Expired access token. This is a problem because an access token is how the API authorizes the user.");
                        }
                        if (!idToken) {
                            throw new Error("No ID token. This is a problem because it indicates that the user is not authenticated.");
                        }
                        if (auth.tokenManager.hasExpired(idToken)) {
                            throw new Error("Expired ID token. This is a problem because it indicates that the user is not authenticated.");
                        }
                        if (!refreshToken) {
                            throw new OktaConfigurationError("No refresh token. Ensure that the Okta application includes a \"Grant type\" of \"Refresh Token\".");
                        }
                        setStatus({
                            tag: "active",
                            tokens: { accessToken: accessToken, idToken: idToken, refreshToken: refreshToken },
                            appConfig: { 'appConfig': appConfig }
                        });
                        return [2 /*return*/];
                }
            });
        }); })()
            .catch(function (e) {
            if (!canceler) {
                if ("service" in e) {
                    var errDetails = JSON.stringify({
                        service: e.service,
                        message: e.message,
                    });
                    window.location.hash = "#/configuration-error?e=".concat(encodeURIComponent(errDetails));
                }
                else if (e.errorCode === "access_denied") {
                    window.location.hash = "#/configuration-error?e=".concat(encodeURIComponent(JSON.stringify({
                        service: 'Okta',
                        message: 'ACCESS_DENIED',
                    })));
                }
                else {
                    auth.token.getWithRedirect();
                }
            }
        });
        return function () {
            canceler = true;
            auth.stop();
        };
    }, []);
    return status.tag !== "pending"
        ? (_jsxs(_Fragment, { children: [_jsx(Tokens.Provider, __assign({ value: status.tag === "active" ? __assign(__assign({}, status.tokens), status.appConfig) : null }, { children: children })), _jsx(Dialog, __assign({ open: status.tag === "error" && status.dismissed === "yes", size: "medium", title: "Session expired", footerContent: _jsx("div", __assign({ style: { display: "flex", justifyContent: "flex-end" } }, { children: _jsx(ButtonGroup, __assign({ style: { flexDirection: "row-reverse" } }, { children: _jsx(Button, __assign({ motif: "primary", icon: ExitToApp, onClick: function () {
                                    auth.tokenManager.clear();
                                    window.location.reload();
                                } }, { children: "Sign in again" })) })) })) }, { children: _jsx("div", { children: "I'm sorry, but an error occurred while attempting to renew your session. Please click the button below to sign in again. You may also cancel to remain on this screen, but please note that application features will not work as expected until you sign in again." }) }))] }))
        : null;
}
export function useAuth() {
    var _a, _b;
    var tokens = useContext(Tokens);
    if (!tokens) {
        return {
            accessToken: "",
            profile: {
                name: "",
                firstName: "",
                lastName: "",
                sub: "",
                email: "",
                region: "",
                countryCode: ""
            },
            appConfig: {},
            signOut: function () {
            },
        };
    }
    var _setRegionFlag = function (_oktaIssuer) {
        switch (_oktaIssuer) {
            case process.env.OKTA_ISSUER_NAB:
                return 'NAB';
            case process.env.OKTA_ISSUER_EUB:
                return 'EUB';
            default:
                return 'Internal';
        }
    };
    var lanId = tokens.accessToken.claims.sub;
    return {
        accessToken: tokens.accessToken.accessToken,
        profile: {
            name: tokens.idToken.claims.name,
            firstName: tokens.accessToken.claims.firstName,
            lastName: tokens.accessToken.claims.lastName,
            email: tokens.accessToken.claims.email,
            lanID: lanId.substring(0, lanId.indexOf("@")),
            uid: tokens.accessToken.claims.uid,
            region: _setRegionFlag((_b = (_a = tokens === null || tokens === void 0 ? void 0 : tokens.accessToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.iss),
            countryCode: tokens.accessToken.claims.countryCode
        },
        appConfig: tokens.appConfig,
        signOut: function () {
            var _a;
            localStorage.removeItem('region');
            switch ((_a = this.profile) === null || _a === void 0 ? void 0 : _a.region) {
                case 'NAB':
                    auth.options.clientId = process.env.OKTA_CLIENT_ID_NAB;
                    auth.options.issuer = process.env.OKTA_ISSUER_NAB ? process.env.OKTA_ISSUER_NAB : "";
                    break;
                case 'EUB':
                    auth.options.clientId = process.env.OKTA_CLIENT_ID_EUB;
                    auth.options.issuer = process.env.OKTA_ISSUER_EUB ? process.env.OKTA_ISSUER_EUB : "";
                    break;
            }
            auth.signOut({
                revokeAccessToken: true,
                revokeRefreshToken: true,
                clearTokensBeforeRedirect: true,
            });
        },
    };
}
